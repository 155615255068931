@import "./variables";
.container {
  width: 100%;
}
.title {
  margin: 1rem 0;
}
.error {
  background-color: $error-background;
}
.form-container {
  display: block;
}

.form-item {
  display: block;
  padding: 0.5rem 0;
}
.form-label {
  padding: 0.5rem 0;
  display: block;
  color: $primary;
  font-size: 0.7em;
}
.form-item label p {
  margin: 0;
  padding: 0;
}
.form-item input {
  display: block;
  width: 100%;
  color: $primary;
  font-size: 1rem;
  border: none;
  padding: 0.7rem;
  margin: 0.2rem 0;
  background-color: $tertiary;
  :focus {
    /* when user clicks on input field */
    border: 1px solid $secondary;
    outline: none;
  }
}
.form-item input::placeholder {
  color: $primary;
  font-size: 1rem;
  opacity: 0.5;
}
.input-error {
  border: 1px solid $error;
}

.button {
  border: none;
  padding: 0.5rem 0.7rem;
  margin: 1rem 0;
  border-radius: 1rem;
  border: 1px solid $accent;
  color: $accent;
  background-color: inherit;
  // font-weight: bold;
  font-size: 1em;
  cursor: pointer;
}
