@import "./variables";
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // height: 100vh;
  align-items: center;
  padding: 1rem;
  //   margin-top: 50vh;
  //   transform: translateY(-50%);

  h1 {
    font-size: 1.5em;
    width: 100%;
  }
  p {
    width: 100%;
    margin: 0.5rem 0;
    font-size: 0.7em;
  }
}

.button-container {
  width: 100%;
  button {
    background-color: inherit;
    border: none;
    color: inherit;
    cursor: pointer;
    padding: 0.4rem 0.7rem;
    border-radius: 1rem;
    border: 1px solid $primary;
    margin-top: 1rem;
  }
  button:hover {
    text-decoration: underline;
  }
  .reset-password {
    border: none;
    padding: 0.4rem 1rem;
  }
}
