@import "./variables";
.container {
  padding: 2rem 0;
  // background-color: $secondary;
}
.error {
  background-color: $error-background;
}
.ul {
  padding: 0;
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  // align-items: stretch;
  // justify-content: space-between;
  gap: 1.5rem;
  .user-item {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem;
    height: 12rem;
    border-radius: 1rem;
    line-height: 12rem;
    border: none;
    background-color: inherit;
    color: inherit;
    background-size: cover;
    background-position: center;
    box-shadow: 0 0 1rem rgba($primary, 0.05);
    position: relative;
    &::before {
      content: "";
      position: absolute;
      border-radius: 1rem;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $tertiary;
      opacity: 0.6;
      z-index: 1;
      transition: opacity 200ms ease;
    }
    button {
      all: unset;
      display: block;
      z-index: 1;
      cursor: pointer;
      h1 {
        display: block;
        min-width: 100%;
        text-align: center;
        padding: 0 0.5rem;
        font-size: 1.3em;
        max-width: 80%;
        // z-index: 1;
        opacity: 0.8;
      }
    }
  }
}

.item-menu {
  font-size: 1.5rem;
  line-height: 1rem;
  box-sizing: content-box;
  padding: 0 1rem;
  color: $primary;
  opacity: 0.7;
  z-index: 2;
  position: relative;
  cursor: pointer;
  transition: all 200ms ease;

  &:hover,
  &:focus {
    opacity: 1;
    transform: scale(1.1);
  }

  .menu {
    // display: none;
    position: absolute;
    left: 0%;
    top: 100%;
    width: 200%;
    list-style-type: none;
    background-color: $primary;
    color: $dark;
    padding: 0;
    border-radius: 0.3rem;
    svg {
      color: $accent;
    }
    li {
      font-size: 1rem;
      height: auto;
      margin: 0;
      padding: 0.7rem 1rem;
      border-bottom: 2px solid $dark;
      transition: padding 200ms ease;
      display: flex;
      align-items: center;
      &:last-child {
        border-bottom: none;
      }
      &:hover,
      &:focus {
        padding-left: 1.5rem;
        padding-right: 0.5rem;
      }
    }
  }
}

.confirm-container {
  @include confirm();
}
