@import "./variables";

/* .form-title {
  padding: 1rem 0;
} */
.form {
  display: block;
  width: 100%;
}
.created {
  background-color: $success-background;
  padding: 1rem;
  width: 100%;
  text-align: center;
}
.error {
  background-color: $error-background;
  padding: 1rem;
  width: 100%;
  text-align: center;
}
.form-item {
  padding: 0.5rem 0;
  display: block;
  color: $primary;
  font-size: 0.7em;
  input {
    display: block;
    width: 100%;
    color: $primary;
    font-size: 1rem;
    border: none;
    padding: 0.7rem;
    margin: 0.2rem 0;
    background-color: $tertiary;
    :focus {
      /* when user clicks on input field */
      border: 1px solid $secondary;
      outline: none;
    }
  }
  input::placeholder {
    color: $primary;
    font-size: 1rem;
    opacity: 0.5;
  }
  textarea {
    font-family: "Raleway", sans-serif;
    display: block;
    width: 100%;
    color: $primary;
    font-size: 1rem;
    border: none;
    padding: 0.7rem;
    margin: 0.2rem 0;
    background-color: $tertiary;
  }
  textarea::placeholder {
    color: $primary;
    font-size: 1rem;
    opacity: 0.5;
  }
}

.button {
  display: flex;
  align-items: center;
  border: none;
  padding: 0.7rem 1.1rem;
  margin: 1rem 0;
  border-radius: 1rem;
  border: 1px solid $accent;
  color: $accent;
  background-color: inherit;
  // font-weight: bold;
  font-size: 1em;
  cursor: pointer;
  transition: padding 0.3s ease;
  &:hover {
    padding: 0.7rem 1.5rem;
  }
}
