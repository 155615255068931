@import "./variables";
.app {
  display: block;
  background-color: $primary;
  position: relative;
  width: 100%;
  overflow-x: hidden;
  overflow-y: visible;
}
.burger {
  font-size: 2.5rem;
  cursor: pointer;
  // margin: 0.5rem;
  box-sizing: content-box;
  padding: 1rem;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  color: $dark;
  transition: color 0.5s;
}
.primary-color {
  color: $primary;
}
.account-container {
  position: absolute;
  background-color: $dark;
  color: $primary;
  width: 100%;
  min-height: 100vh;
  z-index: 2;
  @include mq($medium) {
    width: 400px;
    right: 0;
    max-height: 100vh;
    overflow-y: scroll;
  }
}

.confirm-container {
  @include confirm();
  button {
    transition: background-color 0.3s ease-in-out;
    font-weight: bold;
    &:hover {
      background-color: $success-background;
    }
  }
}
